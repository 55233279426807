import React, { useEffect, useState } from 'react';
import { bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import Button from '../../../../components/Button/Button';
import Field, { hasDataInFields } from '../../Field';
import { AutoComplete, Input, Tabs } from 'antd';
import SectionContainer from '../SectionContainer';
import css from './SectionHero.module.css';
import { FormattedMessage } from 'react-intl';
import './index.css';
import { useHistory } from 'react-router-dom';
import { ReactComponent as DropDownIcon } from './dropdown.svg';
import { createResourceLocatorString } from '../../../../util/routes';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { useConfiguration } from '../../../../context/configurationContext';
import { getCategorySearchParams } from '../../../../util/misc';

// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionHero = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
  } = props;

  const [activeTab, setActiveTab] = useState('product');
  const [inputOnType, setInputOnType] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [values, setValues] = useState([]);

  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const config = useConfiguration();
  const listingCategories = config.categoryConfiguration.categories;
  const professionOptions = config.listing.listingFields.find(lf => lf.key == "professions")?.enumOptions || [];

  const isService = activeTab === "service";
  const isProduct = activeTab === "product";
  const isTravel = activeTab === "travel";

  useEffect(() => {
    let allCategories = [];

    if (isService) {
      professionOptions.map(op => {
        allCategories.push({ value: op.label });
      });

    } else {
      listingCategories.map(category => {
        if ((isProduct && category.id.endsWith("--p")) || (isTravel && category.id.endsWith("--t"))) {
          allCategories.push({ value: category.name });
          if (category.subcategories && category.subcategories.length > 0) {
            category.subcategories.map(subcategory => {
              allCategories.push({ value: subcategory.name });
            });
          };
        };
      });
    };

    setValues(allCategories);
  }, [activeTab]);

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const onSelect = data => {
    setSelectedValue(data);
    setInputOnType(false);
  };

  const tabOnChange = key => {
    setActiveTab(key);
    setSelectedValue();
  };

  const tabs = [
    {key: "service", label: <FormattedMessage id="LandingPage.hireTab" />},
    {key: "product", label: <FormattedMessage id="LandingPage.shopTab" />},
    {key: "travel", label: <FormattedMessage id="LandingPage.travelTab" />},
  ];

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={classNames(rootClassName || css.root)}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
          <div className={css.tabsContainer}>
            {tabs.map(tab => {
              const isActive = activeTab == tab.key;
              return <div
                key={tab.key}
                className={`${css.tab} ${isActive && css.activeTab}`}
                onClick={() => tabOnChange(tab.key)}
              >
                {tab.label}
              </div>
            })}
          </div>

          <div className={css.inputsWrapper}>
            <AutoComplete
              className={css.autoCompleteInput}
              options={values}
              value={selectedValue}
              onSelect={onSelect}
              size="large"
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
            >
              <Input
                size="large"
                style={{ borderRadius: '4px', width: '100%', height: '56px' }}
                enterButton
                onChange={e => {
                  setSelectedValue(e.currentTarget);
                  setInputOnType(true);
                }}
                placeholder={isService ? 'I need a ...' : isProduct ? 'Browse categories' : 'Travel'}
                suffix={<DropDownIcon />}
              />
            </AutoComplete>
            <Button
              className={css.searchButton}
              disabled={!selectedValue || inputOnType}
              onClick={() => {
                const linkParams = getCategorySearchParams({
                  activeTab,
                  listingCategories,
                  selectedValue,
                  professionOptions,
                });

                if (isService && typeof window !== "undefined") {
                  localStorage.setItem("profession from hero", linkParams.pub_profession);
                };

                history.push(
                  createResourceLocatorString(
                    isService ? 'NewListingPage' : isProduct ? "ProductsSearchPage" : "TravelSearchPage",
                    routeConfiguration,
                    {},
                    {
                      ...linkParams,
                      type: activeTab == "service" ? "service" : "product",
                    }
                  )
                )
              }}
            >
              <FormattedMessage id={isService ? "LandingPage.searchButton.service" : "LandingPage.searchButton.product"} />
            </Button>
          </div>
        </header>
      ) : null}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionHero.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
};

SectionHero.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionHero;
